import React from "react"
import { Link } from "gatsby"
import { Icon } from "react-icons-kit"
import { ic_web_outline } from "react-icons-kit/md/ic_web_outline"
import { code } from "react-icons-kit/entypo/code"
import { ic_saved_search } from "react-icons-kit/md/ic_saved_search"
import { stopwatch } from "react-icons-kit/entypo/stopwatch"
import { shield } from "react-icons-kit/fa/shield"
import { signal } from "react-icons-kit/fa/signal"
import { cloud } from "react-icons-kit/fa/cloud"
import { globe } from "react-icons-kit/fa/globe"
import { gears } from "react-icons-kit/fa/gears"
import NavBar from "../components/NavBar"
import Footer from "../components/Footer"
import PageHeader from "../components/PageHeader"
import Metatags from "../components/metatags"
import UseSiteMetadata from "../components/UseSiteMetadata"

import IconCo2 from "../svg/CO2.svg"

const IndexES = ({ pageContext, location }) => {
  const { siteUrl } = UseSiteMetadata()
  const fullUrl = `${siteUrl}${location.pathname}`
  const schemaData = {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@context": "https://schema.org",
        "@type": "Organization",
        email: "info@netbistrot.com",
        name: "NetBistrot",
        logo: "https://netbistrot.com/netbistrot-icon-540.png",
      },
      {
        "@type": "WebSite",
        "@id": "https://netbistrot.com/es/",
        url: "https://netbistrot.com/es/",
        name: "NetBistrot",
        inLanguage: "es-ES",
      },
    ],
  }
  return (
    <>
      <Metatags
        title="NetBistrot - Digital agency"
        description="Grandes websites para negocios pequenos."
        url={fullUrl}
        image="https://netbistrot.com/netbistrot-icon-540.png"
        keywords="web design, web studio, serverless, user experience, social media, graphic design"
        language={pageContext.langKey}
        schemaData={schemaData}
      />
      <NavBar />
      <div className="wrapper z-0">
        <PageHeader />
        <div className="main mx-2">
          <div className="section section-typo">
            <div className="mx-auto max-w-4xl">
              <div className="text-center ">
                <span className="text-4xl">
                  Grandes websites para negocios pequeños.
                </span>
              </div>
              <div className="h-8" />
              <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
                <div className="col-span-2" />
                <div className=" col-span-8">
                  <p className="text-2xl">Lo que hacemos:</p>
                  <div className="h-4" />
                  <p className="text-2xl pb-4">
                    Sitios web de alto rendimiento
                  </p>
                  <p className="text-2xl">Carga de páginas súper rápidas</p>
                  <p className="text-xl pl-6">mejor experiencia de usuario</p>
                  <p className="text-xl pl-6">
                    mejor participación del usuario
                  </p>
                  <p className="text-xl pl-6">mejor posicionamiento SEO</p>
                  <div className="h-4" />
                  <p className="text-2xl">Bajo consumo eléctrico</p>
                  <p className="text-xl pl-6">responsabilidad ambiental</p>
                  <div className="h-4" />
                  <p className="text-2xl">Menores costes de mantenimiento</p>
                  <div className="h-4" />
                  <p className="text-2xl">
                    Optimización de mecanismo de búsqueda
                  </p>
                  <p className="text-xl pl-6">mejor posicionamiento SEO</p>
                  <div className="h-4" />
                  <p className="text-2xl">Escalabilidad</p>
                  <p className="text-xl pl-6">
                    acceso garantizado incluso durante los picos de demanda
                  </p>
                  <div className="h-8" />
                  <Link
                    className="btn-icon rounded text-lg text-white bg-blue-600 hover:bg-blue-800 focus:outline-none focus:ring px-4 py-2 font-semibold"
                    to="/es/static-websites/"
                  >
                    Cómo lo hacemos?
                  </Link>
                </div>
              </div>
              <div className="h-16" />
              <div className=" col-span-2 md:col-span-1 grid grid-cols-1 gap-4">
                <div className="h-8" />
                <div className="text-center ">
                  <span className="text-2xl">
                    Estos son los conceptos detrás de nuestro trabajo:
                  </span>
                </div>
              </div>
              <div className="h-10" />
              <div className="grid grid-cols-2 gap-x-10 gap-y-16">
                <div className=" col-span-2 md:col-span-1 grid grid-cols-1 gap-4">
                  <div>
                    <div className="w-3/12">
                      <Icon size={64} icon={ic_web_outline} />
                    </div>
                    <div className="ml-2 col-span-1 md:col-span-12">
                      <span className="text-2xl">diseño personalizado</span>
                      <br />
                      <span className="text-lg">
                        Tus necesidades no son las mismas que las de los demás y
                        un diseño personalizado ayuda a mejorar tu presencia
                        online. Creamos un proyecto de diseño web a medida para
                        satisfacer cualquier requerimiento técnico o estético.
                      </span>
                    </div>
                  </div>
                </div>
                <div className=" col-span-2 md:col-span-1 grid grid-cols-1 gap-4">
                  <div>
                    <div className="w-3/12">
                      <Icon size={64} icon={code} />
                    </div>
                    <div className="ml-2 col-span-1 md:col-span-10">
                      <span className="text-2xl">código personalizado</span>
                      <br />
                      <span className="text-lg">
                        Tus necesidades no son las mismas que las de los demás y
                        un código personalizado resolverá cualquier necesidad
                        que puedas tener.
                      </span>
                    </div>
                  </div>
                </div>
                <div className=" col-span-2 md:col-span-1 grid grid-cols-1 gap-4">
                  <div>
                    <div className="w-3/12">
                      <Icon size={64} icon={cloud} />
                    </div>
                    <div className="ml-2 col-span-1 md:col-span-10">
                      <span className="text-2xl">cloud hosting</span>
                      <br />
                      <span className="text-lg">
                        Las técnicas de la nube están en constante evolución y
                        utilizamos las más recientes para alojar tus sistemas.
                      </span>
                    </div>
                  </div>
                </div>
                <div className=" col-span-2 md:col-span-1 grid grid-cols-1 gap-4">
                  <div>
                    <div className="w-3/12">
                      <Icon size={64} icon={ic_saved_search} />
                    </div>
                    <div className="ml-2 col-span-1 md:col-span-10">
                      <span className="text-2xl">mejores prácticas SEO</span>
                      <br />
                      <span className="text-lg">
                        Cumplimos con todos los requisitos de los principales
                        motores de búsqueda y redes sociales para que sus
                        algoritmos puedan encontrar fácilmente tu sistema y
                        darles la mejor clasificación.
                      </span>
                    </div>
                  </div>
                </div>
                <div className=" col-span-2 md:col-span-1 grid grid-cols-1 gap-4">
                  <div>
                    <div className="w-3/12">
                      <IconCo2
                        style={{
                          width: "100%",
                          height: "auto",
                          maxWidth: "64px",
                          display: "inline",
                        }}
                      />
                    </div>
                    <div className="ml-2 col-span-1 md:col-span-10">
                      <span className="text-2xl">
                        huella mínima de CO<sub>2</sub>
                      </span>
                      <br />
                      <span className="text-lg">
                        Diseñamos sistemas que utilizan los recursos de la nube
                        solo cuando son necesarios. Esto significa que el
                        consumo eléctrico es mínimo.
                      </span>
                    </div>
                  </div>
                </div>
                <div className=" col-span-2 md:col-span-1 grid grid-cols-1 gap-4">
                  <div>
                    <div className="w-3/12">
                      <Icon
                        size={64}
                        style={{ maxWidth: "86px" }}
                        icon={shield}
                      />
                    </div>
                    <div className="ml-2 col-span-1 md:col-span-10">
                      <span className="text-2xl">Seguridad primero</span>
                      <br />
                      <span className="text-lg">
                        A menos que sea estrictamente necesario, no solicitamos
                        un inicio de sesión. Y cuando lo es utilizamos las
                        técnicas más recientes. Solo se puede acceder a cada
                        sistema base a través de la autenticación de dos
                        factores con los permisos mínimos para la tarea en
                        cuestión.
                      </span>
                    </div>
                  </div>
                </div>
                <div className=" col-span-2 md:col-span-1 grid grid-cols-1 gap-4">
                  <div>
                    <div className="w-3/12">
                      <Icon size={64} icon={stopwatch} />
                    </div>
                    <div className="ml-2 col-span-1 md:col-span-10">
                      <span className="text-2xl">rápida performance</span>
                      <br />
                      <span className="text-lg">
                        Utilizamos varios recursos para garantizar que tu
                        sistema sea lo más rápido posible y que tus usuarios lo
                        noten. Como los motores de búsqueda también valoran la
                        velocidad, este requisito es de suma importancia.
                      </span>
                    </div>
                  </div>
                </div>
                <div className=" col-span-2 md:col-span-1 grid grid-cols-1 gap-4">
                  <div>
                    <div className="w-3/12">
                      <Icon size={64} icon={signal} />
                    </div>
                    <div className="ml-2 col-span-1 md:col-span-10">
                      <span className="text-2xl">escalabilidad</span>
                      <br />
                      <span className="text-lg">
                        Como los sistemas que desarrollamos son serverless y
                        usan recursos de la nube solo cuando son necesarios,
                        tenemos mucha más flexibilidad que un sistema basado en
                        servidor para reaccionar cuando hay un pico en las
                        solicitudes o para reducir la velocidad cuando hay pocos
                        usuarios.
                      </span>
                    </div>
                  </div>
                </div>
                <div className=" col-span-2 md:col-span-1 grid grid-cols-1 gap-4">
                  <div>
                    <div className="w-3/12">
                      <Icon size={64} icon={cloud} />
                    </div>
                    <div className="ml-2 col-span-1 md:col-span-10">
                      <span className="text-2xl">nativo de la nube</span>
                      <br />
                      <span className="text-lg">
                        Diseñamos sistemas que utilizan las características más
                        recientes de la nube. Reemplazamos servidores con
                        microservicios que se ejecutan solo cuando es necesario
                        y CDN (redes de distribución de contenido) que siempre
                        están disponibles.
                      </span>
                    </div>
                  </div>
                </div>
                <div className=" col-span-2 md:col-span-1 grid grid-cols-1 gap-4">
                  <div>
                    <div className="w-3/12">
                      <Icon size={64} icon={globe} />
                    </div>
                    <div className="ml-2 col-span-1 md:col-span-10">
                      <span className="text-2xl">CDN</span>
                      <br />
                      <span className="text-lg">
                        Los sistemas que desarrollamos se implementan en una
                        docena de ubicaciones en todo el mundo para garantizar
                        que tus usuarios reciban contenido de un CDN cercano con
                        un retraso mínimo dondequiera que estén.
                      </span>
                    </div>
                  </div>
                </div>
                <div className=" col-span-2 md:col-span-1 grid grid-cols-1 gap-4">
                  <div>
                    <div className="w-3/12">
                      <Icon size={64} icon={gears} />
                    </div>
                    <div className="ml-2 col-span-1 md:col-span-10">
                      <span className="text-2xl">API</span>
                      <br />
                      <span className="text-lg">
                        Desarrollamos APIs para resolver las necesidades de tus
                        sistemas y expandir sus habilidades a través de recursos
                        de terceros como sistemas de pago con empresas de
                        logística.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="h-20" />
        </div>
        <Footer lang="pt" />
      </div>
    </>
  )
}

export default IndexES
